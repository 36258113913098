<template>
  <div>
        <jy-query class="query_area" size="small">
            <jy-query-item label="充值单号">
                <el-input v-model="form.tradeId"></el-input>
            </jy-query-item>
            <jy-query-item label="企业名称">
                <el-input v-model="form.unitNa" />
            </jy-query-item>
            <jy-query-item label="所属机构">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="orgNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="充值流水号">
                <el-input v-model="form.seriesNu"></el-input>
            </jy-query-item>
            <jy-query-item label="充值方式">
                <el-select v-model="form.chl">
                    <el-option :value="0" label="企业对公转账"></el-option>
                    <el-option :value="1" label="现金"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="交易时间">
                <el-date-picker type="daterange" :clearable="false" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" :value="[form.createTBegin, form.createTEnd]" @input="onDateSelected"></el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="query">查询</el-button>
                <el-button size="small" type="primary" @click="reset">重置</el-button>
                <!-- <el-button size="small" type="primary">导出</el-button> -->
            </template>
        </jy-query>
        <div>搜索结果合计：订单数 {{total}} 笔，交易消费总金额 {{totalSum}} 元</div>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="dataList">
                <jy-table-column type="index" label="序号" width="60"></jy-table-column>
                <jy-table-column label="交易时间" prop="tradeTime" min-width="160"></jy-table-column>
                <jy-table-column label="充值单号" prop="tradeId" min-width="140"></jy-table-column>
                <jy-table-column label="企业名称" prop="unitNa" min-width="140"></jy-table-column>
                <jy-table-column label="所属机构" prop="orgNa" min-width="140"></jy-table-column>
                <jy-table-column label="充值流水号" prop="seriesNu" min-width="140"></jy-table-column>
                <jy-table-column label="充值方式" min-width="120">
                    <template v-slot="scope">
                        {{ getCHLName(scope.row.tradeWay) }}
                    </template>
                </jy-table-column>
                <jy-table-column label="充值前账户余额(元)" prop="pBalance" min-width="120"></jy-table-column>
                <jy-table-column label="充值金额(元)" prop="amount" min-width="120"></jy-table-column>
                <jy-table-column label="充值后账户余额(元)" prop="aBalance" min-width="120"></jy-table-column>
                <jy-table-column label="备注" prop="remark" min-width="200"></jy-table-column>
                <jy-table-column label="操作人" prop="userNa" min-width="120"></jy-table-column>
            </jy-table>
        </div>
        <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        <institutions-tree :showType="[1]" :modal="false" ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
  </div>
</template>

<script>
import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
import {tradeWay} from '@/common/js/wordbook'

export default {
    components: {
        institutionsTree
    },

    data() {
        return {
            total: 0,
            pageSize: 10,
            pageIndex: 1,
            loading: false,

            unitList: [],
            orgNa: '',
            dataList: [],

            form: {
                tradeId: null,
                unitNa: null,
                orgId: null,
                seriesNu: null,
                chl: null,
                createTBegin: '',
                createTEnd: ''
            },

            totalSum:0
        }
    },

    created() {
        this.getList();
    },

    methods: {
        query() {
            this.getList();
        },

        reset() {
            this.pageIndex = 1
            this.pageSize = 10
            Object.keys(this.form).forEach(key => {
                this.form[key] = ''
            })
            this.form.chl = null
            this.orgNa = ''
            this.getList()
        },

        getList() {
            this.loading = true;
            this.$http.post('/finance/account/detailList', {
                io: 0,
                ...this.form,
                chl: this.form.chl !== undefined ? this.form.chl : undefined
            })
                .then(res => {
                    this.totalSum = res.detail.extraData.sum
                    this.total = res.detail.total;
                    this.dataList = res.detail.data.reduce((prev, cur) => {
                        const obj = Object.assign({}, ...Object.values(cur));
                        obj.unitNa = cur.unitInfo && cur.unitInfo.cName;
                        prev.push(obj);
                        return prev;
                    }, []);
                })
                .finally(() => {
                    this.loading = false;
                })
        },

        getCHLName(chl) {
            let list = tradeWay()
            let item = list.find(one=>one.value==chl)
            return item ? item.label : '-';
        },

        onDateSelected(value) {
            [this.form.createTBegin, this.form.createTEnd] = value;
        },

        selectInstitutions() {
            this.$refs.institutions.init();
        },

        addInstitutions(data) {
            this.form.orgId = data.orgId;
            this.orgNa = data.orgNa;
        },

        handleSizeChange(pageSize) {
            this.pageSize = pageSize
        },

        handleCurrentChange(pageIndex) {
            this.pageIndex = pageIndex
        }
    }
}
</script>

<style>

</style>